import React from "react"
import ReviewStyle from "./ReviewStyle.module.styl"

function Review({
  avatar,
  color_white,
  description,
  person_name,
  person_position,
}) {
  return (
    <div
      className={color_white ? ReviewStyle.review_white : ReviewStyle.review}
    >
      <div className={ReviewStyle.review_wrapper}>
        <div className={ReviewStyle.review_content}>
          <div className={ReviewStyle.avatar_holder}>
            <img className={ReviewStyle.avatar} src={avatar} alt="avatar" />
          </div>
          <p className={ReviewStyle.description}> {description}</p>

          <div className={ReviewStyle.person}>
            <div className={ReviewStyle.line} />
            {person_name}
          </div>
          <div className={ReviewStyle.person_position}>{person_position}</div>
        </div>
      </div>
    </div>
  )
}

export default Review
