import React, { useState } from "react"
import VishenLakhianiSectionStyle from "./VishenLakhianiSectionStyle.module.styl"
import Image from "gatsby-image"
import Cross from "../../../../assets/images/cross2.svg"
import Cross2 from "../../../../assets/images/cross3.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function VishenLakhianiSection({ openModal, vishenLakhianiSectionImage }) {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={VishenLakhianiSectionStyle.vishen_lakhiani_section}>
      <div className={VishenLakhianiSectionStyle.image_holder}>
        <Image
          className={VishenLakhianiSectionStyle.image}
          fluid={vishenLakhianiSectionImage[0].node.childImageSharp.fluid}
        />
        <div className={VishenLakhianiSectionStyle.name_holder}>
          <div className={VishenLakhianiSectionStyle.line} />
          <h2>Foreword by Vishen Lakhiani</h2>
        </div>
        <img
          className={VishenLakhianiSectionStyle.cross}
          src={Cross}
          alt="cross"
        />
        <img
          className={VishenLakhianiSectionStyle.cross_2}
          src={Cross2}
          alt="cross"
        />
      </div>
      <div className={VishenLakhianiSectionStyle.vishen_lakhiani}>
        <div className={VishenLakhianiSectionStyle.vishen_lakhiani_text}>
          <h2>
            Greek American engineer, physician, and entrepreneur Peter Diamandis
            (best known for being founder and chairman of the XPRIZE Foundation)
            once famously said that if you’re holding a smartphone in your hand
            today, you have more access to information than the president of the
            United States did in the late 1990s. The smartphone gives every
            single individual on Earth the ability to make an impact through
            knowledge acquired by research or through the sharing of messages
            that influence people, politics, and business. In other words, the
            sum of human knowledge is now in the palm of your hands.
          </h2>

          <div className={VishenLakhianiSectionStyle.btn_holder}>
            <div
              onClick={openModal}
              role="button"
              tabIndex={0}
              onKeyDown={openModal}
              className={VishenLakhianiSectionStyle.read_full_btn}
            >
              Read Full Foreword <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VishenLakhianiSection
