import React from "react"
import AboutTheBookSectionStyle from "./AboutTheBookSectionStyle.module.styl"
import Footer from "../../../../common/footer/Footer"

class AboutTheBookSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { width: 0 }
  }

  componentDidMount = () => {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    return (
      <section className={AboutTheBookSectionStyle.about_the_book_section}>
        <div
          className={AboutTheBookSectionStyle.about_the_book_content_wrapper}
        >
          <h3>
            About the book
            <div className={AboutTheBookSectionStyle.line} />
          </h3>
          <iframe
            src="https://player.vimeo.com/video/455759582"
            width="100%"
            height={
              this.state.width > 992
                ? "650"
                : this.state.width < 600
                ? "250"
                : "350"
            }
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
          <div className={AboutTheBookSectionStyle.content}>
            Hook Point: How to Stand Out in a 3-Second World, by out of the box
            thinker Brendan Kane, breaks down the most effective strategies to
            generate new opportunities, innovate and scale your business, and
            create a compelling brand — both online and off — so you can thrive
            in the new micro-attention world in which we live.
          </div>
        </div>
        <Footer color={true} />
      </section>
    )
  }
}

export default AboutTheBookSection
