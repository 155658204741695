import React from "react"
import TheBookTemplate from "../modules/theBook/TheBook.template"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"

const TheBook = ({ data }) => {
  return (
    <Layout>
      <SEO title="The Book" />
      <div className='animation'>

      <TheBookTemplate
        homePageBookSectionImage={data.homePageBookSectionImage.edges}
        vishenLakhianiSectionImage={data.vishenLakhianiSectionImage.edges}
        aboutTheAuthorSectionImage={data.aboutTheAuthorSectionImage.edges}
      />
      </div>
    </Layout>
  )
}

export default TheBook
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    homePageBookSectionImage: allFile(
      filter: { relativeDirectory: { eq: "homePageBookSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
     vishenLakhianiSectionImage: allFile(
      filter: { relativeDirectory: { eq: "vishenLakhianiSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }   
     aboutTheAuthorSectionImage: allFile(
      filter: { relativeDirectory: { eq: "aboutTheAuthorSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
