import React, { useState } from "react"
import AboutTheAuthorSectionStyle from "./AboutTheAuthorSectionStyle.module.styl"
import Image from "gatsby-image"
import Footer from "../../../../common/footer/Footer"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

const AboutTheAuthorSection = ({ aboutTheAuthorSectionImage }) => {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={AboutTheAuthorSectionStyle.about_the_author_section}>
      <div className={AboutTheAuthorSectionStyle.image_holder}>
        <Image
          className={AboutTheAuthorSectionStyle.image}
          fluid={aboutTheAuthorSectionImage[0].node.childImageSharp.fluid}
        />
        <div className={AboutTheAuthorSectionStyle.name_holder}>
          <div className={AboutTheAuthorSectionStyle.line} />
          <h2>Brendan Kane</h2>
        </div>
      </div>
      <div className={AboutTheAuthorSectionStyle.about_the_author}>
        <div className={AboutTheAuthorSectionStyle.about_the_author_content}>
          <h2>
            About the
            <span>author.</span>
          </h2>
        </div>
      </div>
      <Footer color={true} />
    </section>
  )
}

export default AboutTheAuthorSection
