import React from "react"
import TheBookHeroSection from "./components/theBookHeroSection/TheBookHeroSection"
import NewsletterSection from "../newsletter/NewsletterSection"
import AboutTheBookSection from "./components/aboutTheBookSection/AboutTheBookSection"
import VishenLakhianiSection from "./components/vishenLakhianiSection/VishenLakhianiSection"
import VishenLakhianiModal from "./components/vishenLakhianiSection/vishenLakhianiModal/VishenLakhianiModal"
import AboutTheAuthorSection from "./components/aboutTheAuthorSection/AboutTheAuthorSection"
import BuyTheBookSection from "./components/buyTheBookSection/BuyTheBookSection"
import AboutTheAuthorTextSection from "./components/aboutTheAuthorTextSection/AboutTheAuthorTextSection"
import ReviewSection from "./components/reviewsSection/ReviewSection"
import Review from "./components/reviewsSection/shared/review/Review"
import Avatar1 from "./../../assets/images/avatar3.png"
import Avatar2 from "./../../assets/images/avatar2.png"
import Avatar3 from "./../../assets/images/avatar1.png"
import Avatar4 from "./../../assets/images/avatar4.png"
import Avatar5 from "./../../assets/images/avatar6.png"
import Avatar6 from "./../../assets/images/avatar5.png"
import Avatar7 from "./../../assets/images/avatar7.png"
import Avatar8 from "./../../assets/images/avatar8.png"
import Avatar9 from "./../../assets/images/avatar9.png"
import Avatar10 from "./../../assets/images/avatar10.png"
import Avatar11 from "./../../assets/images/avatar11.png"
import Avatar12 from "./../../assets/images/avatar12.png"

class TheBookTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
      width: 0,
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth })
  }
  openModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
  }
  render() {
    const { modalIsOpen, width } = this.state
    return (
      <>
        <TheBookHeroSection
          homePageBookSectionImage={this.props.homePageBookSectionImage}
        />
        <AboutTheBookSection />
        <VishenLakhianiSection
          modalIsOpen={modalIsOpen}
          openModal={() => this.openModal()}
          vishenLakhianiSectionImage={this.props.vishenLakhianiSectionImage}
        />
        <AboutTheAuthorSection
          aboutTheAuthorSectionImage={this.props.aboutTheAuthorSectionImage}
        />
        <AboutTheAuthorTextSection />

        <ReviewSection>
          <Review
            description={
              "“Brendan is relentless in his pursuit of finding new ways to help marketers do their job better.”"
            }
            avatar={Avatar1}
            person_name={"Latham Arneson"}
            person_position={
              "former VP of digital marketing at Paramount Pictures"
            }
          />

          <Review
            color_white={true}
            description={
              "“Do you want to create demand for your brand or business? Brendan shares methods that work in the 24/7 ubiquitous world we live in.”"
            }
            avatar={Avatar2}
            person_name={"Jonathan Skogmo"}
            person_position={"Founder and CEO of Jukin Media"}
          />
        </ReviewSection>

        <ReviewSection>
          <Review
            color_white={width < 992 ? false : true}
            description={
              "“If you want to become a long-lasting, successful brand both online and off, look no further. Brendan helps you find the messages that get people to pay attention time and again.”"
            }
            avatar={Avatar3}
            person_name={"Melissa Ambrosini"}
            person_position={
              "bestselling author, speaker, podcaster, and entrepreneur"
            }
          />

          <Review
            color_white={width < 992 ? true : false}
            description={
              "“A must-read. In a world of constant transformation, Hook Point is the answer to standing out and growing your brand or business.”"
            }
            avatar={Avatar4}
            person_name={"Keith Ferrazzi"}
            person_position={
              "New York Times bestselling author of Never Eat Alone"
            }
          />
        </ReviewSection>

        <ReviewSection>
          <Review
            description={
              "“What makes you or your product worth paying attention to? Brendan Kane will help you answer that question. His flashlight pierces the pop-cultural fog more effectively than anyone else’s.”"
            }
            avatar={Avatar5}
            person_name={"Jon Jashni"}
            person_position={
              "founder of Raintree Ventures; former president and chief creative officer of Legendary Entertainment"
            }
          />

          <Review
            color_white={true}
            description={
              "“If you want to become a lasting, successful brand online and off, Hook Point is a must-read. Brendan delivers proven strategies that are sure to help you stand out and grab people’s attention.”"
            }
            avatar={Avatar6}
            person_name={"Sally Newell Cohen"}
            person_position={
              "senior vice president of global communications at ICANN; former COO of Toastmasters International"
            }
          />
        </ReviewSection>

        <ReviewSection>
          <Review
            color_white={width < 992 ? false : true}
            description={
              "“Brendan speaks a language I understand. He makes navigating social media and standing out in crowded markets easy and effective. He’s become my go-to guy for all these things!”"
            }
            avatar={Avatar7}
            person_name={"LaLa Anthony"}
            person_position={
              "New York Times bestselling author, businesswoman, producer, and actress"
            }
          />
          <Review
            color_white={width < 992 ? true : false}
            description={
              "“There is a small window to capture your audience’s attention today. Brendan’s book is a must-read to refocus your acquisition strategy on quality as opposed to quantity.”"
            }
            avatar={Avatar8}
            person_name={"Faisel Durrani"}
            person_position={
              "former GM/EVP of Def Jam Recordings and president of Marketing Live Nation"
            }
          />
        </ReviewSection>
        <ReviewSection>
          {/* <Review
            description={
              "“What makes you or your product worth paying attention to? Brendan Kane will help you answer that question. His flashlight pierces the pop-cultural fog more effectively than anyone else’s.”"
            }
            avatar={Avatar9}
            person_name={"Jon Jashni"}
            person_position={
              "founder of Raintree Ventures; former president and chief creative officer of Legendary Entertainment"
            }
          /> */}
          <Review
            color_white={false}
            description={
              "“An insightful read. I genuinely appreciate being a part of this book.”"
            }
            avatar={Avatar10}
            person_name={"John Kilcullen"}
            person_position={
              "creator and publisher of the iconic For Dummies bestselling book series and brand"
            }
          />
          <Review
            color_white={width < 992 ? false : true}
            description={
              "“Hook Points are the essence of my success; without it I’m just another guy trying to get your attention, and we all know about how well that works these days!”"
            }
            avatar={Avatar11}
            person_name={"Michael Breus "}
            person_position={
              "PhD, best-selling author, internationally renowned as “The Sleep Doctor”"
            }
          />
        </ReviewSection>

        <Review
          color_white={true}
          description={
            "“In our highly distracted world, Brendan’s book reveals how to create content and messages that make people pay attention.”"
          }
          avatar={Avatar12}
          person_name={"Michael Gervais "}
          person_position={
            "PhD, high-performance psychologist; host of the Finding Mastery podcast"
          }
        />

        <BuyTheBookSection
          homePageBookSectionImage={this.props.homePageBookSectionImage}
        />
        <NewsletterSection />
        {modalIsOpen === true && (
          <VishenLakhianiModal
            modalIsOpen={modalIsOpen}
            closeModal={() => this.openModal(!modalIsOpen)}
          />
        )}
      </>
    )
  }
}

export default TheBookTemplate
