import React from "react"
import ReviewSectionStyle from './ReviewSectionStyle.module.styl'

const ReviewSection = ({children}) => {
  return (
    <section className={ReviewSectionStyle.review_section}>
      {children}
    </section>
  )
}

export default ReviewSection