import React from "react"
import { Modal } from "react-bootstrap"
import VishenLakhianiModalStyle from "./VishenLakhianiModalStyle.module.styl"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const VishenLakhianiModal = ({ modalIsOpen, closeModal }) => {
  return (
    <Modal
      show={modalIsOpen}
      onHide={closeModal}
      className={"modal_for_the_book"}
    >
      <Modal.Body className={VishenLakhianiModalStyle.modal_body}>
        <div className={VishenLakhianiModalStyle.exit_icon}>
          <FontAwesomeIcon onClick={closeModal} icon={faTimes} />
        </div>
        <p>
          Greek American engineer, physician, and entrepreneur Peter Diamandis
          (best known for being founder and chairman of the XPRIZE Foundation)
          once famously said that if you’re holding a smartphone in your hand
          today, you have more access to information than the president of the
          United States did in the late 1990s. The smartphone gives every single
          individual on Earth the ability to make an impact through knowledge
          acquired by research or through the sharing of messages that influence
          people, politics, and business. In other words, the sum of human
          knowledge is now in the palm of your hands.
          <br />
          <br /> Access to this vast amount of information is a gift, but it
          also creates a problem—most people feel inundated, overwhelmed, and
          stressed out because of the sheer magnitude of content flowing to them
          on a daily basis. In 1970, 500 ads were seen by the average American
          per day;1 today it’s between 4,000 and 10,000 ads per day.2 This has
          led to a phenomenon where human attention spans are getting shorter.
          These days, if you want to get your message out to the world, you
          often have as little as three seconds to do so. <br />
          <br />
          Think about it. The world’s first contact with your brand or business
          is often on social media, where there are 147,000 photos uploaded,
          54,000 shared links, and 317,000 status updates on Facebook every
          minute;3 more than 95 million posts shared on Instagram every day;4
          and one billion hours of content watched on YouTube daily5—there’s an
          incredible amount of distraction that prevents you from being seen.
          Whether you like it or not, if you want people to notice your brand or
          business—online or off— it’s essential to master the art of grabbing
          attention quickly.
          <br /> <br />
          Luckily, Brendan Kane is a true expert in helping people stand out.
          Brendan’s claim to fame happened when he decided to figure out how he
          could get one million fans in 30 days flat. After that achievement, he
          wrote a book about the process called One Million Followers. The book
          led to speaking opportunities, which is how I met Brendan, when I put
          him onstage at Mindvalley’s A-Fest to share his knowledge about
          digital and social media. I was so impressed by what he had to share
          that I asked him to become an adviser for my company, Mindvalley.{" "}
          <br />
          <br />
          In a matter of months, Brendan transformed the way my team and I
          communicate on the internet. He gave us the incredible ability to
          boost our company’s revenue—as once you have a loyal following, you
          can get important messages out there, which ultimately helps you
          monetize that following. As a health advocate, one of the most
          important messages Brendan has helped us communicate to date was about
          the harmful effects and outlandish marketing claims of Coca-Cola. He
          guided us in launching a campaign with a video on the dangers of
          high-fructose corn syrup that amassed ten million views within a week
          (and it’s still growing). <br />
          <br />
          In short, not only did Brendan’s ideas help our company thrive, but
          they also allowed us to disseminate important information, impact the
          consciousness of our audience, and become more effective activists.
          This is why it’s so important to know how to stand out—you can have
          all the followers in the world, but to truly make a difference you
          need to know how to communicate with them. <br />
          <br />
          This book will give you a process that helps you better communicate
          with potential audience members and business partners, as well as with
          current customers. You will learn how to make them loyal, engaged,
          core visionaries who support your visions, ideas, and mission. To get
          you started, here are some crucial tips about making an impact that
          I’ve learned as I’ve grown my following and reach for Mindvalley:
          <span className={VishenLakhianiModalStyle.list_item}>
            1. Be aware of whom you’re talking to. Tailor your communication to
            your audience, whether it’s millennials, baby boomers, or an
            ultraniche group of people such as auto mechanics.
          </span>
          <span className={VishenLakhianiModalStyle.list_item}>
            {" "}
            2. Become your demographic. Mine comprises people who are passionate
            about transformation, health, and personal growth. Every single
            month I make it a point to read, attend a program or seminar, and
            work with a transformational leader. I put myself through
            experiences that make me wiser, healthier, and happier. Then, I
            share these experiences with my audience so that I am not only a
            mentor and a thought leader but also a member of my demographic—a
            consumer of transformation.
          </span>
          <span className={VishenLakhianiModalStyle.list_item}>
            3. Know your why—that is, know why you do what you do. I do what I
            do because of what I call the “Eve question.” Eve is my six-year-old
            daughter, and before every action I take, I ask myself, “Will this
            make the world better for Eve?” This is why my communication is not
            just about spreading personal-growth information, but also about
            encouraging people to leave the world a better place for all the
            young children who will inherit this planet.
          </span>
          <span className={VishenLakhianiModalStyle.list_item}>
            {" "}
            4. Be authentic and real. In my communication, I don’t just share
            ideas from my business, I also openly share my difficulties and
            challenges. My most-commented-on and engaged posts over the last two
            years were the ones where I spoke about personal events—how my
            parents lost their home in a fire, how I was recovering from a
            serious injury and would have to go through a year of
            rehabilitation, and even a post where I shared why my partner and I
            were ending our marriage. People demand authenticity from leaders
            today and pay more attention when you’re real and authentic.
          </span>
          Brendan covers all these tips in depth (and so many more) in this
          book. I bet you’re eager to start reading, but before you embark on
          this journey to making an impact, I want to address one hurdle that
          may be in some of your minds: Am I worthy of sharing? Am I unique or
          special? Do my thoughts really need to be heard? <br />
          <br />
          The answer is: You won’t know until you try. So why not start today?{" "}
          <br />
          <br />
          In 2008, when I first started my company, I only published other
          people’s work. Even though I had considerable expertise in certain
          fields, I felt I was too young, too unworthy, and too unaccomplished
          to publish my own thoughts. I didn’t feel good enough until a disaster
          struck several years later—I lost a deal with my biggest author. All
          of a sudden, there was a gap to fill, and I decided that perhaps it
          was a sign I should step up and become an author myself. When I did
          so, the universe seemed to support my choice. Not only did my book,
          The Code of the Extraordinary Mind, become a New York Times
          bestseller, but it hit the number-one spot on Amazon. Yet this was a
          book that I had put off writing for three years because I didn’t think
          I had what it took!
          <br />
          <br />
          All of us will face moments of insecurity and doubt—moments where we
          feel unworthy. Know that these moments are often nothing more than
          self-imposed limitations. So get out there and do your best. You will
          refine, grow, and enhance your ideas when you let them flow out to a
          world that will dissect, comment on, engage with, and mold them. Let
          public opinion and feedback become your friends. No matter where you
          are, put your insecurities aside, and just do it. You’ll get better as
          you practice the tools that Brendan Kane shares right here in this
          book.
          <span style={{ marginTop: 50 }}>Vishen</span>
          <span>Lakhiani Founder, Mindvalley</span>
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default VishenLakhianiModal
