import React, { useState } from "react"
import AboutTheAuthorTextSectionStyle from "./AboutTheAuthorTextSectionStyle.module.styl"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function AboutTheAuthorTextSection() {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={AboutTheAuthorTextSectionStyle.about_the_book_section}>
      <div
        className={
          AboutTheAuthorTextSectionStyle.about_the_book_content_wrapper
        }
      >
        <h3>
          About the author
          <div className={AboutTheAuthorTextSectionStyle.line} />
        </h3>

        <div className={AboutTheAuthorTextSectionStyle.content}>
          Growth strategist Brendan Kane has the answer and will show you how—in
          30 days or less. A wizard of the social media sphere, Kane has built
          online platforms for A-listers including Taylor Swift and Rihanna .
          He’s advised brands such as MTV , Skechers , Vice and IKEA on how to
          establish and grow their digital audience and engagement. Kane has
          spent his career discovering the best tools to turn any no-name into a
          top influencer simply by speaking into a camera or publishing a
          popular blog—and now he’ll share his secrets with you.
        </div>
      </div>
    </section>
  )
}

export default AboutTheAuthorTextSection
