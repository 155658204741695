import React, { useState } from "react"
import TheBookHeroSectionStyle from "./TheBookHeroSectionStyle.module.styl"
import Cross from "../../../../assets/images/cross3.svg"
import Image from "gatsby-image"
import Footer from "../../../../common/footer/Footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false
const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}
function TheBookHeroSection({ homePageBookSectionImage }) {
  const [isVisible, setSectionVisible] = useState(false)

  function onChange() {
    setSectionVisible(!isVisible)
  }
  return (
    <section className={TheBookHeroSectionStyle.the_book_hero_section}>
      <div className={TheBookHeroSectionStyle.get_the_book}>
        <div className={TheBookHeroSectionStyle.get_the_book_content}>
          <h1>Get the book</h1>

          <a
            href="https://book.hookpoint.com/hook-point-ebook/hook-point-ebook-access/"
            target="_blank"
            rel="noreferrer"
            className={TheBookHeroSectionStyle.the_book_btn}
          >
            Buy The Book <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </div>
      </div>
      <div className={TheBookHeroSectionStyle.the_book}>
        {" "}
        <img
          className={TheBookHeroSectionStyle.cross}
          src={Cross}
          alt="cross"
        />
        <Image
          className={TheBookHeroSectionStyle.image}
          fluid={homePageBookSectionImage[0].node.childImageSharp.fluid}
        />
      </div>
      <Footer />
    </section>
  )
}

export default TheBookHeroSection
