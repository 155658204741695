import React from "react"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import BuyTheBookSectionStyle from "./BuyTheBookSectionStyle.module.styl"

const BuyTheBookSection = ({ homePageBookSectionImage }) => {
  return (
    <section className={BuyTheBookSectionStyle.book_section}>
      <Image
        className={BuyTheBookSectionStyle.book_section_image}
        fluid={homePageBookSectionImage[0].node.childImageSharp.fluid}
      />
      <a
        href="https://book.hookpoint.com/hook-point-ebook/hook-point-ebook-access/"
        target="_blank"
        rel='noreferrer'
        className={BuyTheBookSectionStyle.the_book}
      >
        Buy The Book <FontAwesomeIcon icon={faAngleRight} />
      </a>
    </section>
  )
}

export default BuyTheBookSection
